<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>
            <b-form-select
              v-model="selectNickname.code"
              :options="formattedProjectOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Letter Number</label>
            <b-form-input
              size="lg"
              placeholder="Letter Number"
              v-model="filter.code"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>
            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Name"
              v-model="selectedProject.projectName"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Letter Date</label>
            <!-- <b-form-input
              id="input-large"
              size="lg"
              type="date"
              v-model="filter.date"
            ></b-form-input> -->
            <b-form-datepicker
              id="input-large"
              size="lg"
              v-model="filter.date"
              :no-flip="true"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="DD-MM-YYYY"
              reset-button
              reset-value=""
            ></b-form-datepicker>

          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <button class="btn btn-primary btn-md" @click="onShowData">
            <i class="flaticon2-search-1"></i> Show
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-end">
        <vue-excel-xlsx
          :data="data.letter"
          :columns="columns"
          :file-name="
            filter.code_project +
              '-' +
              '' +
              selectedProject.nickname +
              '' +
              '- Lettering'
          "
          :sheet-name="'letter'"
          :file-type="'xlsx'"
        >
          <button
            class="btn btn-success btn-md mr-2"
            :disabled="data.letter.length === 0"
          >
            <i class="fa fa-file-excel"></i>
            Excel
          </button>
        </vue-excel-xlsx>

        <button
          class="btn btn-danger btn-md mr-2"
          @click="onExportPDF"
          :disabled="data.letter.length === 0"
        >
          <i class="fa fa-file-pdf"></i>
          PDF
        </button>
      </div>

      <div class="card-body p-0">
        <div class="table-responsive" ref="contentPDF" id="contentPDF">
          <complete-table :loading="loading" :data="this.data.letter">
            <template #header>
              <tr class="text-left">
                <th style="min-width: 50px" class="pl-7">
                  <span class="text-dark-75">No</span>
                </th>
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Nickname</span>
                </th>
                <th style="min-width: 100px">Type</th>
                <th style="min-width: 100px">Letter Date</th>
                <th style="min-width: 100px">Letter Number</th>
                <th style="min-width: 100px">Subject</th>
                <th style="min-width: 100px">PIC</th>
                <th style="min-width: 100px">From</th>
                <th style="min-width: 100px">To</th>
                <th style="min-width: 101px">Follow Up</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.no }}
                  </span>
                </td>
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.nickname }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.type }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.tanggal_surat).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.nomor_surat }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.subject }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.pic }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.from }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.to }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.follow_up }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable.vue";
import { GET_REPORT_LETTERING } from "@/core/services/store/report-lettering.module";
import { mapGetters, mapState } from "vuex";
import {
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_PLANNER,
  SHOW_PROJECT_DRAFTER,
  SHOW_PROJECT_KOOR,
  SHOW_PROJECT_ENGINER,
  SHOW_PROJECT_MANAGER,
  SHOW_PROJECT_ADMIN,
} from "@/core/services/store/dl.design.module";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { logos } from "@/assets/js/components/logos64bit.js";

export default {
  components: { CompleteTable },
  name: "lettering",
  data() {
    return {
      filter: {
        date: null,
        code_project: null,
        code: null,
      },
      selectNickname: {
        code: null,
        text: null,
      },
      selectedProject: {
        code: null,
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        primpro: null,
        revisi: null,
        nickname: null,
        contract_no: null,
      },
      columns: [
        {
          label: "No",
          field: "no",
        },
        {
          label: "Nickname",
          field: "nickname",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Letter Date",
          field: "tanggal_surat",
        },
        {
          label: "Letter Number",
          field: "nomor_surat",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
          label: "PIC",
          field: "pic",
        },
        {
          label: "From",
          field: "from",
        },
        {
          label: "To",
          field: "to",
        },
        {
          label: "Follow Up",
          field: "follow_up",
        },
      ],
      data: {
        letter: [],
      },
      options: {
        followUp: [
          { value: 1, text: "Yes" },
          { value: 0, text: "No" },
        ],
        letter_type: [
          { value: "in", text: "In" },
          { value: "out", text: "Out" },
          { value: "reply", text: "Reply" },
        ],
        project: [{ value: null, text: null }],
        pic: [{ value: null, text: null }],
      },
      form: {
        code: null,
        id_project: null,
        letter_type: null,
        date: null,
        subject: null,
        pic_code: null,
        from: null,
        to: null,
        follow_up: null,
      },
    };
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    onPDF() {
      console.log(this.currentReportLettering);
    },
    onShowData() {
      this.$store
        .dispatch(GET_REPORT_LETTERING, { params: this.filter })
        .then(() => this.mappingDataLetter(this.currentReportLettering));
    },

    mappingDataProject(projectList) {
      this.options.project = [];
      projectList.map((project) =>
        this.options.project.push({
          text: project.code + "-" + project.nickname,
          value: project.code,
        })
      );
    },
    mappingDataPic(listpic) {
      this.options.pic = [];
      listpic.map((pic) =>
        this.options.pic.push({
          text: pic.code + "-" + pic.name,
          value: pic.code,
        })
      );
    },

    mappingDataLetter(listLetter) {
      let no = 0;
      this.data.letter = [];
      listLetter.map((letter) =>
        this.data.letter.push({
          no: (no = no + 1),
          nickname: letter.project.nickname,
          type: letter.letter_type,
          tanggal_surat: moment(letter.date).format("DD-MM-YYYY"),
          nomor_surat: letter.code,
          subject: letter.subject,
          pic: letter.pic_code,
          from: letter.from,
          to: letter.to,
          follow_up: letter.follow_up === 1 ? "YES" : "NO",
          file: letter.file,
        })
      );
    },

    mappingFormToObject(form) {
      Object.assign(this.data.letter, {
        code: form.code,
        project: {
          code: form.id_project,
          name: null,
          nickname: null,
        },
        letter_type: form.letter_type,
        date: form.date,
        subject: form.subject,
        pic: {
          code: form.pic_code,
          name: null,
        },
        from: form.from,
        to: form.to,
      });
    },

    onExportPDF() {
      moment.locale("id");
      var totalPagesExp = "{total_pages_count_string}";
      var doc = new jsPDF("landscape");
      const today = new Date();
      var projectCode =
        this.filter.code_project !== null ? this.filter.code_project : "-";

      var nickname =
        this.selectedProject.nickname !== null
          ? this.selectedProject.nickname
          : "-";
      var contract_no =
        this.selectedProject.contract_no !== null
          ? this.selectedProject.contract_no
          : "-";
      var logo = logos;

      doc.setFontSize(11);
      var headRows = [
        [
          "No",
          "Nickname",
          "Type",
          "Letter Date",
          "Letter Number",
          "Subject",
          "PIC",
          "From",
          "To",
          "Follow Up",
        ],
      ];
      var body = [];
      this.data.letter.map((c) => {
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });

      doc.autoTable({
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        head: headRows,
        body: body,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text("Reporting Lettering", data.settings.margin.left + 80, 25);
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );

          doc.text(
            `Contract No: ${contract_no}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            30
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            35
          );

          /*  var lines = doc.splitTextToSize(`Project name: ${projectName}`, (pdfInMM-lMargin-rMargin));
          doc.text(
            lines,
            data.settings.margin.left,
           45
          ); */

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      var letterName =
        this.filter.code_project +
        "-" +
        this.selectedProject.nickname +
        "- Lettering.pdf";
      doc.save(letterName);
    },
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lettering" }]);
    /*   await this.$store
      .dispatch(GET_PROJECT_DETAIL, "design")
      .then(() => this.mappingDataProject(this.currentProjectDetails)); */

    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_PLANNER);
    await this.$store.dispatch(SHOW_PROJECT_DRAFTER);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    await this.$store.dispatch(SHOW_PROJECT_ENGINER);
    await this.$store.dispatch(SHOW_PROJECT_MANAGER);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
  },
  computed: {
    ...mapGetters([
      "currentReportLettering",
      "currentPics",
      "currentProjectDetails",
    ]),
    ...mapState({
      loading: (state) => state.lettering.loading,
    }),
    formattedProjectOptions() {
      var result = [{ value: null, text: "Choose Project" }];
      if (this.projectList != undefined) {
        this.projectList.map((project) => {
          result.push({
            value: project.code,
            text: project.code + "-" + project.nickname,
          });
        });
      }
      return result;
    },

    currentProjectCode() {
      return this.selectNickname.code;
    },

    projectList() {
      let projectInterface = this.$store.getters.getterProjectInterface;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectPlanner = this.$store.getters.getterProjectPlanner;
      let projectDrafter = this.$store.getters.getterProjectDrafter;
      let projectKoor = this.$store.getters.getterProjectKoor;
      let projectEnginer = this.$store.getters.getterProjectEnginer;
      let projectManager = this.$store.getters.getterProjectManager;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      let combine1 = this.merge(projectInterface, projectDesign, "code");
      let combine2 = this.merge(combine1, projectPlanner, "code");
      let combine3 = this.merge(combine2, projectDrafter, "code");
      let combine4 = this.merge(combine3, projectKoor, "code");
      let combine5 = this.merge(combine4, projectEnginer, "code");
      let combine6 = this.merge(combine5, projectManager, "code");
      let combine7 = this.merge(combine6, projectAdmin, "code");

      return combine7;
    },
  },
  watch: {
    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.selectedProject, {
          id_project: null,
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          revisi: null,
          primpro: null,
          nickname: null,
          contract_no: null,
        });

        return;
      }

      var resultProject = this.projectList.filter((c) => c.code === value);

      Object.assign(this.selectedProject, {
        id_project: resultProject[0].code,
        ownerName: resultProject[0].owner,
        projectName: resultProject[0].name,
        status: resultProject[0].status,
        contractNumber: resultProject[0].contract_no,
        primpro: resultProject[0].leader,
        revisi: resultProject[0].site_document_revision,
        nickname: resultProject[0].nickname,
        contract_no: resultProject[0].contract_no,
      });

      //this.getDocumentSiteByProject(value);
    },
  },
};
</script>

<style></style>
